"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageToJSON = exports.ImageFromJSONTyped = exports.ImageFromJSON = exports.instanceOfImage = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the Image interface.
 */
function instanceOfImage(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfImage = instanceOfImage;
function ImageFromJSON(json) {
    return ImageFromJSONTyped(json, false);
}
exports.ImageFromJSON = ImageFromJSON;
function ImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'content': !(0, runtime_1.exists)(json, 'content') ? undefined : json['content'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
exports.ImageFromJSONTyped = ImageFromJSONTyped;
function ImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'content': value.content,
        'description': value.description,
    };
}
exports.ImageToJSON = ImageToJSON;
