"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * viandeendirect.eu
 * This API allows selling and buying food through the shortest path between farmers and customers.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageTemplateToJSON = exports.PackageTemplateFromJSONTyped = exports.PackageTemplateFromJSON = exports.instanceOfPackageTemplate = void 0;
var runtime_1 = require("../runtime");
var Image_1 = require("./Image");
/**
 * Check if a given object implements the PackageTemplate interface.
 */
function instanceOfPackageTemplate(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPackageTemplate = instanceOfPackageTemplate;
function PackageTemplateFromJSON(json) {
    return PackageTemplateFromJSONTyped(json, false);
}
exports.PackageTemplateFromJSON = PackageTemplateFromJSON;
function PackageTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'photo': !(0, runtime_1.exists)(json, 'photo') ? undefined : (0, Image_1.ImageFromJSON)(json['photo']),
        'netWeight': !(0, runtime_1.exists)(json, 'netWeight') ? undefined : json['netWeight'],
        'unitPrice': !(0, runtime_1.exists)(json, 'unitPrice') ? undefined : json['unitPrice'],
    };
}
exports.PackageTemplateFromJSONTyped = PackageTemplateFromJSONTyped;
function PackageTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'label': value.label,
        'description': value.description,
        'photo': (0, Image_1.ImageToJSON)(value.photo),
        'netWeight': value.netWeight,
        'unitPrice': value.unitPrice,
    };
}
exports.PackageTemplateToJSON = PackageTemplateToJSON;
